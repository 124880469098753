function openConsole() {
    let vConsole = window.vConsole;
    if (vConsole == null) {
        window.vConsole = new window.VConsole();
    }
}

function closeConsole() {
    let vConsole = window.vConsole;
    if (vConsole) {
        vConsole.destroy();
    }
    window.vConsole = null;
}

export { openConsole, closeConsole }