<template>
    <div class="ask-app-header">
        <div class="aph-web">
            <div class="aph-web-user">
                <div class="aph-web-user-avt">
                    <el-popover v-model="popoverVisible" placement="bottom-start" width="220" trigger="click"
                        popper-class="aph-web-user-avt-pop">
                        <div class="awua-box">
                            <div class="awuab-top">
                                <div class="awuab-top-avt">
                                    <!-- <el-upload class="avatar-uploader" :action="uploadUrl" :data="uploadAvatarParams"
                                        :show-file-list="false" :on-success="handleAvatarSuccess"
                                        :before-upload="beforeAvatarUpload">

                                        <div class="awuab-top-avt-default">
                                            <img v-if="avtImageUrl" height="31px" width="31px" style="border-radius: 50%;"
                                                :src="avtImageUrl" class="awuab-top-avt-default-name-image">
                                            <span v-else class="awuab-top-avt-default-name">{{
                                                userInfo.name.substring(userInfo.name.length - 2, userInfo.name.length)
                                            }}</span>

                                            <span class="awuab-top-avt-default-hover iconfont guoran-xiangji1"></span>
                                        </div>
                                    </el-upload> -->
                                    <el-upload class="avatar-uploader" :http-request="((data) => {
                                        return aliyunOssRequest(data, 'ask')
                                    })" :show-file-list="false" :on-success="handleAvatarSuccess"
                                        :before-upload="beforeAvatarUpload" :auto-upload="true">

                                        <div class="awuab-top-avt-default">
                                            <img v-if="avtImageUrl" height="31px" width="31px" style="border-radius: 50%;"
                                                :src="avtImageUrl" class="awuab-top-avt-default-name-image">
                                            <span v-else class="awuab-top-avt-default-name">{{
                                                userInfo.name.substring(userInfo.name.length - 2, userInfo.name.length)
                                            }}</span>

                                            <span class="awuab-top-avt-default-hover iconfont guoran-xiangji1"></span>
                                        </div>
                                    </el-upload>
                                </div>
                                <div class="awuab-top-name">
                                    <span v-show="!nameEditing" class="awuab-top-name-text">{{
                                        userInfo.name }}
                                        <!-- <i class="iconfont guoran-tongyichicun-16-15-lianjibianji"
                                            @click="editName"></i> -->
                                    </span>
                                    <div v-show="nameEditing" class="awuab-top-name-edit">
                                        <el-input ref="nameEditing" @focus="nameEditingFocus" size="small"
                                            v-model="userInfoEditName"
                                            @keyup.enter.native="saveCustomerInfo('realName', userInfoEditName)"
                                            maxLength="20" placeholder="请输入用户名"></el-input>
                                        <span @click="saveCustomerInfo('realName', userInfoEditName)"
                                            class="atne-save iconfont guoran-icon_duihao-mian2"></span>
                                        <span @click="nameEditing = false"
                                            class="atne-delete iconfont guoran-cuohao2"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="awuab-handle-box">
                                <div class="ahb-cell" @click="invitePopup" v-if="accountSource != 11 && accountSource != 12 && false">
                                    <span class="ahb-cell-icon iconfont guoran-tongyichicun-piliangyaoqing"></span>
                                    <span class="ahb-cell-text">邀请好友一起使用</span>
                                </div>
                                <div @click="toxiaochao" class="ahb-cell">
                                    <span class="ahb-cell-icon iconfont guoran-tongyichicun-piliangbianjiiocn"></span>
                                    <span class="ahb-cell-text">意见反馈</span>
                                </div>
                                <div @click="toRedemptionCode" class="ahb-cell" v-if="accountSource != 11 && accountSource != 12">
                                    <span class="ahb-cell-icon iconfont guoran-duihuanma"></span>
                                    <span class="ahb-cell-text">兑换码</span>
                                </div>
                                <!-- <div class="ahb-cell">
                                    <span class="ahb-cell-icon iconfont guoran-tongyichicun-16-14-shezhiliebiao"></span>
                                    <span class="ahb-cell-text">设置</span>
                                </div> -->
                                <div @click="logout" class="ahb-cell">
                                    <span class="ahb-cell-icon iconfont guoran-tuichu1"></span>
                                    <span class="ahb-cell-text">退出登录</span>
                                </div>
                                <template v-if="false">
                                  <!--   20240110改版将该处的管理后台去掉放到侧边显示    -->
                                  <el-divider v-if="(accountSource == 11 || accountSource == 12) && knowledgeUserName !== '' && knowledgeUserName !== 'null'"></el-divider>
                                  <div @click="goManage" class="ahb-cell" v-if="false && (accountSource == 11 || accountSource == 12) && knowledgeUserName !== '' && knowledgeUserName !== 'null'">
                                    <span class="ahb-cell-icon iconfont guoran-pingtai"></span>
                                    <span class="ahb-cell-text">管理后台</span>
                                  </div>
                                </template>
                            </div>
                        </div>
                        <div class="avatar" slot="reference">
                            <img height="31px" width="31px" style="border-radius: 50%;margin-top: 5px;"
                                v-if="avtImageUrl != ''" :src="avtImageUrl">
                            <span v-else class="aph-web-user-avt-inner">{{
                                userInfo.name.substring(userInfo.name.length - 2, userInfo.name.length) }}</span>
                        </div>
                    </el-popover>
                </div>
                <div class="aph-web-user-info" v-if="$route.path !== '/joinCompany'">
                    <div class="aph-web-user-info-simple">
                        <div class="aph-web-user-info-simple-t">
                            <el-dropdown placement="bottom-start" @command="handleCommand" trigger="click" @visible-change="visibleChange" ref="refSelect">
                                <span class="aph-web-user-info-simple-name" v-if="accountSource != 11 && accountSource != 12">个人<i class="el-icon-arrow-down el-icon--right"></i></span>
                                <span class="aph-web-user-info-simple-name" v-else>
                                    <el-tooltip  v-if="accountSourceName.length > 6" class="item" effect="dark" :content="accountSourceName" placement="top-end">
                                        <span>{{ accountSourceName.slice(0,6) + '...'}}</span>
                                    </el-tooltip>
                                    <span v-else>{{ accountSourceName}}</span>
                                    <i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown" class="app-header-company-list">
                                    <el-dropdown-item :command="item.mainId" v-for="(item,index) in hadJoinCompanyList" :key="index">{{item.name}}</el-dropdown-item>
                                    <el-dropdown-item command="create" divided>新建/加入企业</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                            <template v-if="!onlyShowUserIcon">
                                <template v-if="currentVersion.vipType == 1 || currentVersion.vipType == 2">
                                    <!-- 0：免费版，1：超级会员，2：高级会员  3 1企业版 -->
                                    <div :class="['version-type',currentVersion.vipType === 2 ? 'vip' : 'svip' ]">{{currentVersion.vipType === 2 ? 'VIP' : 'SVIP'}}</div>
                                    <div @click="openPayVersion" class="aph-web-user-info-red-tips" v-if="currentVersion.validDays <= 7">
                                        {{currentVersion.vipType === 2 ? '高级会员' : '超级会员'}}{{currentVersion.expire ? `已到期，请续费` : `还剩${Math.abs(currentVersion.validDays)}天到期，请续费`}}
                                    </div>
                                </template>
                                <span :class="['aph-web-user-info-simple-v',onlyShowUserIcon ? 'company' : 'self']" v-if="accountSource == 11 || accountSource == 12">企业版</span>
                                <span v-else>
                                    <span :class="['aph-web-user-info-simple-v',onlyShowUserIcon ? 'company' : 'self']" v-if="currentVersion.vipType == 0 && currentVersion.expire">个人体验版{{currentVersion.vipType == 0 && currentVersion.expire}}</span>
                                    <div class="version-type svip" @click="openInviteFriendsPopup" v-if=" inviteInfo.tipsText && inviteInfo.tipsText !== '' && (accountSource != 11 && accountSource != 12)">{{inviteInfo.tipsText}}</div>
                                </span>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="aph-web-search" v-if="!onlyShowUserIcon">
                <div class="content">
                    <searchInput
                        style="width:482px"
                        ref="searchIpt"
                        @sendMessage="sendMessage"
                    ></searchInput>
                    <div @click="handleSendMessage" class="aphws-add">
                        <i class="iconfont guoran-tongyichicun-18-30-sousuo"></i>
                        <span class="aphws-add-text">搜索</span>
                    </div>
                </div>
            </div>
                <!-- <div class="win-info-tip"> -->
                    <!-- <span class="iconfont guoran-tongyichicun-24_01-xiaoxitongzhi">
                        <i></i>
                    </span> -->
                <!-- </div> -->
          <div class="aph-web-handle">
            <el-popover
                placement="bottom"
                width="120"
                trigger="hover"
                v-model="addBtnVisable"
            >
              <div class="aphws-add-list">
                <div class="upload-file" @click="uploadFile">
                  <i class="iconfont guoran-tongyichicun-shangchuan"></i>
                  <span>本地上传</span>
                </div>
                <div class="add-knowledge" @click="topAddDoc">
                  <i class="el-icon-plus"></i>
                  <span>新建文本笔记</span>
                </div>
                <div class="ai-ppt" @click="topAiPpt">
                    <i class="iconfont guoran-youhuameihua"></i>
                  <span>AI生成PPT</span>
                </div>
                
              </div>
              <div class="aphws-add aigc" slot="reference">
                <span class="iconfont guoran-tongyichicun-16-13-xinjian"></span>
                <span class="aphws-add-text">新建</span>
              </div>
            </el-popover>
            <el-popover placement="bottom" width="280" trigger="click">
              <div class="awh-downapp">
                <span class="awhd-title">下载APP</span>
                <span class="awhd-des">APP震撼上线，快来使用吧</span>
                <span class="awhd-img">
                            <img height="200" width="200" src="./../../../src/assets/images/downloadApp1.png" alt="" srcset="">
                        </span>
                <span class="awhd-tip">微信扫码下载</span>
              </div>
              <div v-show="false" class="awh-down-btn" slot="reference">
                <span class="iconfont guoran-tongyichicun-piliangxiazai"></span>
                <span class="aphws-add-text">下载APP</span>
              </div>
            </el-popover>
          </div>
        </div>
        <invite-friends ref="inviteFriends" :inviteInfo="inviteInfo" :inviteCodeCopy="inviteCodeCopy"></invite-friends>
        <ask-dialog v-show="redemptionVisable" @closeEvent="redemptionVisable = false" :haveTips="false" class="redemption-popup-content">
            <div slot="popup-name">兑换码</div>
            <div slot="popup-con">
               <div class="redemption-tips">兑换码</div>
               <el-input v-model="redemptionValue" placeholder="请输入"></el-input>
            </div>
            <div slot="dialog-footer" class="lighting-popup-dialog-footer">
                <div class="cancel-btn" @click="redemptionVisable = false">取消</div>
                <div class="confirm-btn" @click="redemptionFn">兑换</div>
            </div>
        </ask-dialog>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
// import Tucao from "./tucao";
import InviteFriends from "@/views/testComponents/InviteFriendsPopup.vue";
import AskDialog from "./AskDialog.vue";
import { multipartUpload, ossFileUrl } from "./AliyunIssUtil"
import { requestUrl } from "../../http/requestUrl";
import { initSsoUid } from '../../http/cookie';
import {redirectUri} from "./../../http/redirectUri";
import searchInput from '@/components/searchInput.vue';
export default {
    name: "AppHeader",
    components: { InviteFriends, AskDialog, searchInput},
    props: {
        // 模板列表
        modeList: {
            type: Array,
            default: () => {
                return []
            }
        },
        inviteInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        inviteCodeCopy: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            userInfoEditName: "",
            avtImageUrl: '',
            uploadUrl: "/api-open/oss/public",
            uploadAvatarParams: {
                token: "8c98087dfd2d48f856d8c95c09115def",
            },
            userInfo: {
                name: "",
            },
            askVersion: 0, // 0 个人版 1 企业版
            nameEditing: false,
            redemptionValue:"",
            redemptionVisable:false,
            popoverVisible:false,
            onlyShowUserIcon:false, // 是否仅展示用户头像
            hadJoinCompanyList:[],
            accountSource:11, // 账号类型 个人  : 8 9 10      企业 : 11 12
            currentAccount:"",
            accountSourceName:"",
            screenWidthTimer: false, // 监听页面宽度变化节流 timer
            isFirst:false,
            knowledgeUserName:'',
            searchStatus: 'show',
            searchText:'111',
            historyContentVisable:false,
            historyList:[
                {
                    id:1,
                    label:'历史搜索历史搜索历史搜索历史搜索历史搜索'
                },
                {
                    id:2,
                    label:'历史搜索'
                },
                {
                    id:3,
                    label:'历史搜索'
                },
                {
                    id:4,
                    label:'历史搜索'
                },
                {
                    id:5,
                    label:'历史搜索'
                },
                {
                    id:6,
                    label:'历史搜索'
                },
                {
                    id:7,
                    label:'历史搜索'
                },
                {
                    id:8,
                    label:'历史搜索'
                },
                {
                    id:9,
                    label:'历史搜索'
                },
                {
                    id:10,
                    label:'历史搜索'
                }
            ],
          addBtnVisable:false
        }

    },
    watch: {
        modeList(newValue) {
            console.log(newValue);
        },
        $route(to, from) {
            console.log(to, from);
            this.onlyShowUserIcon = to.path === '/joinCompany' ? true : false
        },
        isTopSearch: {
            handler() {
                this.screenWidthTimer = false;
                this.screenWidth()

            },
            immediate: true
        },
        // isTestSearch(){
        //     this.screenWidthTimer = false;
        //     this.screenWidth()
        // },
        inviteInfo: {
            handler() {
                this.screenWidthTimer = false;
                this.screenWidth();
            },
            deep: true
        },
        currentVersion: {
            handler(n) {
                console.log(n,'mmmm');
                this.screenWidthTimer = false;
                this.screenWidth();
            },
            deep: true
        }
    },
    computed: {
        ...mapGetters('apphandle', {
            // 是否触发顶部添加知识
            isTopAddDoc: 'getIsTopAddDoc',
        }),
        ...mapGetters('user', {
            currentVersion: 'getCurrentVersion'
        }),
        ...mapGetters('apphandle', {
            // 助手显隐
            isTopSearch: 'getIsTopSearch',
        }),
        ...mapGetters('apphandle', {
            // 是否是切换企业
            isSwitchCompany: 'getIsSwitchCompany',
        }),
        ...mapGetters('apphandle', { // 是否是切换企业
            isSwitchCompany: 'getIsSwitchCompany'
        }),
    },
    methods: {
        ...mapActions('apphandle', [
            'updateIsTopAddDoc'
        ]),
        ...mapActions('apphandle', [
            'updateIsTopAddDocTimestamp'
        ]),
         // 更新
        ...mapActions('apphandle', [
            'updateIsSwitchCompany'
        ]),
        aliyunOssRequest(data) {
            let file = data.file;
            // ossConfig.bucket = "guoranopen-zjk";
            let res = multipartUpload({
                region: "oss-cn-zhangjiakou",
                //云账号AccessKey有所有API访问权限，建议遵循阿里云安全最佳实践，创建并使用STS方式来进行API访问
                accessKeyId: "LTAI4G3QtdEdwkEbihBngAsK",
                accessKeySecret: "OwgdVfc5PeCkIgqIdug660xmiSPchn",
                // stsToken: '<Your securityToken(STS)>',
                bucket: "guoranopen-zjk",
            }, file, (process) => {
                console.log(process);
            });
            return res;
        },
        /**
         * 上传头像
         * @method handleAvatarSuccess
         * @param {}
         * @return
         */
        handleAvatarSuccess(res, file) {
            console.log(183, res, file);
            // ossConfig.bucket = "guoranopen-zjk";
            this.avtImageUrl = URL.createObjectURL(file.raw);
            this.saveCustomerInfo("avt", ossFileUrl({
                region: "oss-cn-zhangjiakou",
                //云账号AccessKey有所有API访问权限，建议遵循阿里云安全最佳实践，创建并使用STS方式来进行API访问
                accessKeyId: "LTAI4G3QtdEdwkEbihBngAsK",
                accessKeySecret: "OwgdVfc5PeCkIgqIdug660xmiSPchn",
                // stsToken: '<Your securityToken(STS)>',
                bucket: "guoranopen-zjk",
            }, res.name));
        },
        beforeAvatarUpload(file) {
            const isSupportedFormat =
                file.type === "image/jpeg" ||
                file.type === "image/png" ||
                file.type === "image/jpeg";
            const isLte2M = file.size / 1024 / 1024 <= 5;

            if (!isSupportedFormat) {
                this.messageFn('error', '上传头像图片只能是 JPG 或 PNG 格式 或 JPEG 格式!');
            }
            if (!isLte2M) {
                this.messageFn('error', '上传头像图片大小不能超过 5MB!');
            }
            return isSupportedFormat && isLte2M;
        },
        // 编辑用户姓名
        editName() {
            this.nameEditing = true;
            this.userInfoEditName = this.userInfo.name;
            this.$nextTick(() => {
                this.$refs.nameEditing.focus();
            })
        },
        // 编辑用户姓名自动聚焦并选中
        nameEditingFocus(e) {
            e.target.select();
        },

        // 顶部触发添加知识 修改状态管理 apphandle 模块
        topAddDoc() {
            console.log(this.isTopAddDoc);
            this.updateIsTopAddDoc({
                status: true,
                fromRouteName: this.$route.name
            });
            this.updateIsTopAddDocTimestamp(new Date().getTime());
            this.$router.push({ name: "IndexView", query: "" });
            this.addBtnVisable = false;
        },
        topAiPpt(){
            this.$router.push({ name: "aippt", query: "" });
        },
        uploadFile(){
          this.$emit('uploadFile')
          this.addBtnVisable = false;
        },
        // 触发机器人搜索展示
        searchBotVisible() {
            console.log(this.$route.path);
            // if (this.$route.path === "/assistant") {
            //     this.messageFn('warning', '请在下方搜索');
            // } else {
            //     this.$emit("searchBotVisible", true);
            // }
            this.searchStatus = 'search'
            this.$nextTick(() =>{
                this.$refs.searchIpt.focus()
            })
        },
        searchBlur() {

        },
        searchInput() {
            console.log(this.searchText);
        },
        handleSendMessage() {
            if(!sessionStorage.getItem("botBindInfo")) {
                this.$http
                .get(requestUrl.knowledge.getBotBindInfo + "?userId=" + localStorage.getItem("_uid"))
                .then((res) => {
                    if (res.data.code === "0") {
                        sessionStorage.setItem('botBindInfo', JSON.stringify(res.data.data))
                        this.$refs.searchIpt.searchEnter()
                    }
                });
            } else {
                this.$refs.searchIpt.searchEnter()
            }
        },
        sendMessage(value) {
            this.$router.push( {
                name: 'searchKnowledge',
                params:{
                    searchText: value
                }
            })
        },
        // 退出登录
        logout() {
            this.$emit("loginOut");
        },
        //邀请好友
        invitePopup() {
            this.popoverVisible = false;
            this.$refs.inviteFriends.isInviteFriends = true;
        },
        // 意见反馈, 打开 toxiaochao 页面
        toxiaochao() {
            let data = {
                "nickname": this.userInfo.name,
                "avatar": (this.avtImageUrl === "" || this.avtImageUrl === "null") ? '' : this.avtImageUrl,
                "openid": localStorage.getItem("_uid")
            };
            // productId = 527647; // 把1221数字换成你的产品ID，否则会不成功
            // Tucao.request(productId, data);
            // window.open("https://support.qq.com/product/527647");
            let urlParam = `openid=${data.openid}&avatar=${data.avatar}&nickname=${data.nickname}`
            let url = `${location.origin}/lightning-web/askbot.html?${encodeURIComponent(urlParam)}`;
            window.open(url);
        },
        // 修改用户头像 姓名
        saveCustomerInfo(type, val) {
            let userInfo = {};
            if (type === "avt") {
                userInfo.profilePhoto = val;
            }
            if (type === "realName") {
                if (val.trim() === "") {
                    this.messageFn('warning', '用户名不能为空哦！');
                    return false;
                } else {
                    userInfo.realName = val;
                }
            }
            userInfo.updateUserAllAccount = true;
            this.$emit("changeUserInfo", userInfo, () => {
                if (type === "avt") {
                    localStorage.setItem("profilePhoto", val);
                    this.messageFn('success', '头像修改成功！');
                }
                if (type === "realName") {
                    this.nameEditing = false;
                    this.userInfo.name = val;
                    // localStorage.setItem("realName", val);
                    localStorage.setItem("knowledgeNickName", val);
                    this.messageFn('success', '用户名修改成功！');
                }
            })
        },
        openPayVersion() {
            this.$router.push({ name: "payVersionView" })
        },
        // 打开兑换码弹框
        toRedemptionCode() {
            this.popoverVisible = false;
            this.redemptionValue = '';
            this.redemptionVisable = true;
        },
        //兑换
        redemptionFn() {
            this.$http.get(requestUrl.pay.useConversionCode + "?code=" + this.redemptionValue).then((res) => {
                if (res.data.code === '0' && res.data.data) {
                    this.messageFn('success', '兑换成功');
                    this.redemptionVisable = false;
                    this.$emit('redemptionSuccess')
                } else {
                    this.messageFn('error', '兑换失败');
                }
            });
        },
        goJoinCompany(){
            this.$router.push({ name: "joinCompany"});
        },
         // 获取用户的所有开通知识管理的企业
        getAllCompany(){
            // this.hadJoinCompanyList = [];
            this.$http.get(`${requestUrl.company.queryMemberCompany}?userPhone=${localStorage.getItem('phone')}`).then((res) => {
                if (res.data.code == 0) {
                    res.data.data.forEach((item) => {
                        item.command = item.personalAccount ? 'self' : 'company'
                        if(item.personalAccount){
                            item.name = '个人'
                        }
                        // if(index < 5){
                        //     this.hadJoinCompanyList.push(item)
                        // }
                    });
                    this.hadJoinCompanyList = res.data.data;
                    sessionStorage.setItem('companyList',JSON.stringify(res.data.data))
                }
            });
        },
        handleCommand(command){
            console.log(command,'command');
            if(command === 'create'){
                this.$router.push({name:"joinCompany"})
            } else {
                let data = {}
                if(localStorage.getItem('_mainId') !== command){
                    let flag = true;
                    this.hadJoinCompanyList.forEach(item => {
                        if(item.mainId === command){
                            console.log(item,'item');
                            data = item;
                            if(!item.personalAccount){
                                flag = item.auditStatus
                            }
                        }
                    })
                    if(flag){
                        this.updateIsSwitchCompany(true);
                        setTimeout(() => {
                            this.switchCompany(command,data)
                        },500)
                    } else {
                        this.messageFn('warning', '请等待管理员审核');
                    }
                }
            }
        },
        // 切换企业
        switchCompany(mainId,datas){
            this.$http.post(`${requestUrl.company.getCompanyToken}`,{
                username:localStorage.getItem('realName'),
                tel:localStorage.getItem('phone'),
                mainId:mainId,
                profilePhoto:localStorage.getItem('profilePhoto') == 'null' ? '': localStorage.getItem('profilePhoto')
            }).then((res) => {
                console.log(res,'res----切换企业');
                if (res.data) {
                    if(res.data === "forbidden"){
                        this.messageFn('error', '该账号已被禁用!');
                        return false;
                    }
                    let loading = this.$loading({
                        lock: true,
                        text: '切换中，请稍等...',
                        spinner: 'el-icon-loading',
                        background: 'hsla(0,0%,100%,.7)',
                        customClass:"switch-company-loading"
                    });
                      // this.updateIsSwitchCompany(false);
                    let token = res.data;
                    // 切换企业的操作
                    this.$http.get(`${requestUrl.company.getToken}?token=${res.data}&setJwtCookie=true`).then((res) => {
                        // this.$store.commit("apphandle/setIsSwitchCompany", false);
                        localStorage.setItem("_uid", res.data.data.userId);
                        sessionStorage.setItem('_uid',res.data.data.userId);
                        localStorage.setItem('_mainId', res.data.data.mainId);
                        // localStorage.setItem("accountType", res.data.data.accountType);
                        localStorage.setItem("roleId", res.data.data.roleId);
                        localStorage.setItem("username", res.data.data.username);
                        localStorage.setItem("profilePhoto", res.data.data.profilePhoto);
                        localStorage.setItem("knowledgeNickName", res.data.data.realName);
                        localStorage.setItem("realName", res.data.data.realName);
                        sessionStorage.setItem("loginSuccessTimes", res.data.data.loginSuccessTimes);
                        localStorage.setItem('rolePrivileges', JSON.stringify(res.data.data.rolePrivileges))
                        localStorage.setItem("phone", res.data.data.phone);
                        localStorage.setItem("accountSource", res.data.data.source);
                        localStorage.setItem("accountSourceName", datas.name);
                        localStorage.setItem("_corpId", datas.corpId);
                        localStorage.setItem("knowledgeUserName", res.data.data.knowledgeUserName);
                        localStorage.setItem('_token',token)
                        sessionStorage.setItem('_token', token)
                        sessionStorage.setItem('isFirstGoIndex',1);
                        localStorage.setItem('haveKnowledgePermission', res.data.data.haveKnowledgePermission);
                        localStorage.setItem('memberInDepartDto', JSON.stringify(res.data.data.memberInDepartDto) || '[]');
                        localStorage.setItem('haveManagerPermission', res.data.data.haveManagerPermission);
                        localStorage.setItem("authStatus",  res.data.data.authStatus);
                        initSsoUid();
                        this.$emit('switchCompany');
                        setTimeout(() => {
                            loading.close();
                        },2000)
                        // this.updateIsSwitchCompany(false);
                        if(this.$route.path === '/index'){
                            // this.updateIsSwitchCompany(true);
                        } else {
                            this.$router.push({ name: "IndexView", query: "" })
                        }
                    })
                    .catch((error) => {
                        console.log(error,'error');
                        this.messageFn('error', 'token校验异常,请尝试退出重新登录',2000);
                    });
                }
            });
        },

        screenWidth(){
            // if (!this.screenWidthTimer) {
            //     this.screenWidthTimer = true;
            //     setTimeout(() => {
            //         // 代码繁琐 , 后续优化
            //         // let totalWidth = document.body.clientWidth;
            //         // let searchDom = document.querySelector('.aph-web-search');
            //         // let aphwsBoxDom = document.querySelector('.aph-web-search')
            //         // if (!searchDom || !aphwsBoxDom) {
            //         //     return false
            //         // }
            //         // if (this.isTopSearch) {
            //         //     document.querySelector('.aph-web-search').style.width = document.body.clientWidth - 387 - 16 + 'px';
            //         //     document.querySelector('.aph-web-search').style.minWidth = 1300 - 387 - 16 + 'px';
            //         //     totalWidth = document.body.clientWidth - 387 - 16;
            //         // } else {
            //         //     document.querySelector('.aph-web-search').style.width = '100vw';
            //         //     document.querySelector('.aph-web-search').style.minWidth = '1300px';
            //         // }
            //         // console.log(totalWidth,'totalWidth',this.isTopSearch);
            //         // if(totalWidth <= 1100){
            //         //     // console.log('小于1100');
            //         //     document.querySelector('.aphws-box').style.width = '130px';
            //         //     this.handlerStyle(true)
            //         // } else if (totalWidth > 1100 && totalWidth <= 1300) {
            //         //     this.handlerStyle(true)
            //         //     // console.log('1100-1300');
            //         //     document.querySelector('.aphws-box').style.width = '230px';
            //         // } else if (totalWidth > 1300 && totalWidth <= 1500) {
            //         //     this.handlerStyle()
            //         //     if (this.inviteInfo.tipsText !== '' && this.currentVersion.validDays <= 7) {
            //         //         document.querySelector('.aphws-box').style.width = '250px';
            //         //     } else {
            //         //         document.querySelector('.aphws-box').style.width = '350px';
            //         //     }
            //         // } else {
            //         //     this.handlerStyle()
            //         //     // console.log('大于1500');
            //         //     document.querySelector('.aphws-box').style.width = '430px';
            //         // }
            //         // }
            //         this.screenWidthTimer = false;
            //     }, 200)
            // }
        },
        handlerStyle(flag) {
            if (this.isTopSearch && flag) {
                if (this.inviteInfo.tipsText !== '' && this.currentVersion.validDays <= 7) {
                    document.querySelector('.aph-web-search').style.alignItems = 'flex-start';
                    document.querySelector('.aph-web-search').style.justifyContent = 'flex-start';
                    document.querySelector('.aph-web-search').style.left = '500px';
                    document.querySelector('.aph-web-search').style.width = 'auto';
                    document.querySelector('.aph-web-search').style.minWidth = 'auto';
                } else {
                    document.querySelector('.aph-web-search').style.alignItems = 'center';
                    document.querySelector('.aph-web-search').style.justifyContent = 'center';
                    document.querySelector('.aph-web-search').style.left = '0'
                }
            } else {
                document.querySelector('.aph-web-search').style.alignItems = 'center';
                document.querySelector('.aph-web-search').style.justifyContent = 'center';
                document.querySelector('.aph-web-search').style.left = '0'
            }

        },
        openInviteFriendsPopup() {
            this.popoverVisible = false;
            this.$refs.inviteFriends.isInviteFriends = true;
        },
        goManage(){
             // this.$http.post(requestUrl.company.getCompanyToken+'?tel=' + localStorage.getItem('phone') + '&mainId=' + localStorage.getItem('_mainId')+ '&username='+localStorage.getItem('realName'))
            this.$http.post(`${requestUrl.company.getCompanyToken}`,{
                username:localStorage.getItem('realName'),
                tel:localStorage.getItem('phone'),
                mainId:localStorage.getItem('_mainId'),
                profilePhoto:localStorage.getItem('profilePhoto') == 'null' ? '' : localStorage.getItem('profilePhoto'),
            }).then((res) => {
                console.log(res,'token');
                this.popoverVisible = false;
                if(res.data){
                    let redirectUrl = '';
                    let tempwindow = window.open("_blank");
                    // haveManagerPermission用户是否有管理后台操作权限，若该值为true,点击管理后台跳转管理平台，
                    // haveKnowledgePermission为null且haveKnowledgePermission=true，跳转知识管理后台
                    let haveManagerPermission = localStorage.getItem('haveManagerPermission');
                    let haveKnowledgePermission = localStorage.getItem('haveKnowledgePermission');
                    if(haveManagerPermission){
                        redirectUrl = redirectUri[process.env.VUE_APP_NODE_ENV] ? redirectUri[process.env.VUE_APP_NODE_ENV].callback : redirectUri['development'].callback;
                        if(window.location.hostname.includes('ebot.isheely.com')) {
                            tempwindow.location = 'https://portal.ebot.isheely.com' + '#/auth' + '?token=' + res.data + '&isAskLightning=1';
                        } else {
                            tempwindow.location = redirectUrl + '#/auth' + '?token=' + res.data + '&isAskLightning=1';
                        }
                    } else if(haveManagerPermission == 'null' && haveKnowledgePermission == 'true') {
                        redirectUrl = redirectUri[process.env.VUE_APP_NODE_ENV] ? redirectUri[process.env.VUE_APP_NODE_ENV].wisdom : redirectUri['development'].wisdom;
                        if(window.location.hostname.includes('ebot.isheely.com')) {
                            tempwindow.location = 'https://wisdom.ebot.isheely.com/lightning-web' + '#/auth' + '?token=' + res.data + '&isAskLightning=1';
                        } else {
                            tempwindow.location = redirectUrl + '/#/?token=' + res.data;
                        }
                    }
                } else {
                    this.messageFn('error', res.message);
                }
            });

            // let redirectUrl = redirectUri[process.env.VUE_APP_NODE_ENV] ? redirectUri[process.env.VUE_APP_NODE_ENV].callback : redirectUri['development'].callback;
            // console.log(redirectUrl,'redirectUrl');
            // let tempwindow = window.open("_blank");
            // tempwindow.location = redirectUrl;

        },
        handlerSwitchCompany(){
            this.userInfo.name = localStorage.getItem("realName");
            this.avtImageUrl = localStorage.getItem("profilePhoto") == "null" ? "https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/2023/03/18/11/48/878f878d-6343-4f48-ada7-86f05211910a/askdefaltleftavt.png" : localStorage.getItem("profilePhoto");
            this.accountSource = Number(localStorage.getItem('accountSource'));
            this.currentAccount = localStorage.getItem('_mainId');
            this.accountSourceName = localStorage.getItem('accountSourceName');
            this.knowledgeUserName = localStorage.getItem('knowledgeUserName');
            // console.log(this.accountSource,this.accountSourceName,'SwitchCompany');
            this.$forceUpdate()
            this.getAllCompany();
        },
        // 在下拉框展开时添加底部固定项(注意该方法可能随版本更新而不适用)
        visibleChange(visible){
            if(visible){
                this.$nextTick(() => {
                    let dom = document.querySelector('.app-header-company-list');
                    if (!dom){
                        return
                    }
                    dom.style.height = (this.hadJoinCompanyList.length * 36) + 'px';
                    if (dom.scrollHeight >= (window.innerHeight - 200)) {
                        // console.log("页面有滚动条", Number(dom.style.height.replace('px','')));
                        document.querySelector('.el-dropdown-menu__item--divided').style.width = (dom.offsetWidth - 42) + 'px'; // padding : 20*2    border : 1*2
                        document.querySelector('.el-dropdown-menu__item--divided').style.top =  Number( dom.style.top.replace('px',''))  + dom.offsetHeight + 'px';
                        // dom.scrollWidth = dom.scrollWidth - 34;
                    } else {
                        // console.log("页面没有滚动条");
                        document.querySelector('.el-dropdown-menu__item--divided').style.width = (dom.offsetWidth - 42) + 'px';// padding : 20*2    border : 1*2
                        document.querySelector('.el-dropdown-menu__item--divided').style.top =  Number( dom.style.top.replace('px',''))  + Number( dom.style.height.replace('px','')) + 20 + 'px'
                    }
                })
            }
        }
    },
    mounted() {
        if (this.$route.path === '/joinCompany' ){
            this.onlyShowUserIcon = true;
        }
        this.$nextTick(() => {
            setTimeout(() => {
                this.handlerSwitchCompany();
                window.addEventListener('resize', () => {
                    this.visibleChange(true);
                    this.screenWidth();
                })
            }, 600);
        })
    },
    destroyed() {
        window.addEventListener('resize', () => {})
    }
}
</script>
<style lang="less">
.app-header-company-list{
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    max-width: 400px;
    min-width: 150px;
    .el-dropdown-menu__item{
        text-overflow: ellipsis;
        display: -webkit-box; //使用了flex，需要加
        overflow: hidden;     //超出隐藏
        word-break: break-all; //纯英文、数字、中文
        text-overflow: ellipsis;  //省略号
        -webkit-box-orient: vertical; //垂直
        -webkit-line-clamp: 1;  //显示一行
        white-space:pre-line;
    }
    .el-dropdown-menu__item--divided{
        position: fixed;
        background-color: #fff;
        z-index: 2222;
        margin-top: 0;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
        border-radius: 0 0 4px 4px;
        &::before{
            display: none;
        }
    }
}
</style>
<style lang="less" scoped>
@import "./../../assets/less/common.less";

.aph-web-user-avt-pop {
    padding: 14px 0 0 0 !important;

    .awua-box {
        .awuab-top {
            display: flex;
            justify-content: flex-start;
            // border-bottom: solid 1px #E0E6F7;
            padding-bottom: 12px;
            align-items: center;

            .awuab-top-avt:hover {
                width: 31px;

                .awuab-top-avt-default {
                    .awuab-top-avt-default-hover {
                        display: block;
                    }
                }
            }

            .awuab-top-avt {
                flex: none;
                margin-left: 12px;
                width: 31px;
                height: 31px;
                border-radius: 50%;
                overflow: hidden;

                .awuab-top-avt-default {
                    position: relative;
                    line-height: 31px;
                    width: 31px;
                    text-align: center;

                    .awuab-top-avt-default-name {
                        background: @system_ask_color;
                        line-height: 31px;
                        color: white;
                        font-size: 13px;
                        font-weight: 700;
                        text-align: center;
                    }

                    .awuab-top-avt-default-name-image {
                        line-height: 31px;
                        color: white;
                        font-size: 13px;
                        font-weight: 700;
                        text-align: center;
                    }

                    .awuab-top-avt-default-hover {
                        display: none;
                        position: absolute;
                        width: 31px;
                        height: 31px;
                        border-radius: 50%;
                        background: rgba(0, 0, 0, 0.3);
                        top: 0;
                        left: 0;
                        color: white;
                        margin: 0 auto;
                    }
                }
            }

            .awuab-top-name {
                flex: auto;
                margin-left: 6px;
                display: flex;
                align-items: center;

                .awuab-top-name-text {
                    margin-left: 6px;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    margin-right: 12px;

                    i {
                        font-size: 14px;
                        cursor: pointer;
                        margin-left: 6px;
                    }

                    i:hover {
                        color: @system_ask_color;
                        font-weight: 600;
                    }
                }

                .awuab-top-name-edit {
                    margin-right: 12PX;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-right: 12px;

                    .el-input {
                        margin-right: 8px;
                    }

                    .atne-delete {
                        font-size: 18px;
                        cursor: pointer;
                        margin-left: 6px;
                        color: #FF8089;
                    }

                    .atne-save {
                        font-size: 18px;
                        cursor: pointer;
                        color: #759FFF;
                    }
                }
            }
        }

        .awuab-handle-box {
            // margin-bottom: 12px;

            .el-divider{
                margin: 4px 0;
            }
            .ahb-cell-active,
            .ahb-cell:hover {
                background: #759FFF;
                color: white;
            }

            .ahb-cell {
                color: #404040;
                height: 38px;
                display: flex;
                align-items: center;
                padding: 0 18px;
                cursor: pointer;

                .ahb-cell-icon {
                    margin-right: 6px;
                }

                .ahb-cell-text {}
            }
        }
    }
}

.ask-app-header {
    height: 40px;
    padding: 10px 0;
    position: relative;

    .aph-web {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        position: relative;

        .aph-web-user {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-left: 14px;
            position: relative;
            z-index: 2;

            .aph-web-user-avt {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 40px;

                .avatar {
                    cursor: pointer;
                }

                .aph-web-user-avt-inner {
                    display: block;
                    width: 31px;
                    height: 31px;
                    line-height: 31px;
                    background: @system_ask_color;
                    border-radius: 50%;
                    color: white;
                    font-size: 12px;
                    text-align: center;
                    font-weight: 700;
                    cursor: pointer;
                }
            }

            .aph-web-user-info {
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .aph-web-user-info-simple {
                    display: flex;
                    flex-direction: column;
                    text-align: left;

                    .aph-web-user-info-simple-t {
                        display: flex;
                        align-items: center;

                        .aph-web-user-info-simple-name {
                           cursor: pointer;
                           max-width: 300px;
                            font-size: 14px;
                            font-weight: 500;
                        }

                        .version-type {
                            // width: 35px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 19px;
                            border-radius: 9px;
                            font-size: 12px;
                            margin-left: 10px;
                            font-weight: 600;
                            padding: 0 5px;
                            cursor: pointer;

                            &.vip {
                                background: #FBF1DE;
                                color: #edb54a;

                            }

                            &.svip {
                                background: #60666E;
                                color: #EACD76;
                                // width: 42px;
                            }
                        }

                        .aph-web-user-info-red-tips {
                            background: #FFEBEC;
                            border-radius: 5px;
                            height: 20px;
                            line-height: 20px;
                            padding: 0 6px;
                            color: #FE5965;
                            font-size: 12px;
                            margin-left: 10px;
                            cursor: pointer;
                        }
                        .aph-web-user-info-simple-v {
                            font-size: 12px;
                            font-weight: 500;
                            color: #A9B3C6;
                            cursor: pointer;
                            height: 17px;
                            line-height: 17px;
                            text-align: center;
                            font-size: 12px;
                            padding: 0 7px;
                            margin-left: 8px;
                            &.company{
                                background: #E6ECFF;
                                border: 1px solid #CDDAFF;
                                border-radius: 9px;
                                color: #366AFF;
                            }
                            &.self{
                                background: #E1F7F6;
                                border: 1px solid #7BDEDA;
                                border-radius: 9px;
                                color:#00C2BB;
                            }
                        }

                        /deep/.el-select{
                            // height: 20px;
                            .el-input__inner{
                                padding: 0;
                                border: none;
                                // height: 20px;
                                // line-height: 20px;
                            }
                            .el-input__suffix{
                                // top: 9px;
                            }
                        }
                    }
                    .aph-web-user-info-simple-tips{
                        color: #A9B3C6;
                        margin-top: 4px;
                        font-size: 12px;
                    }

                }

                .aph-web-user-switch-box {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .aph-web-user-switch {
                        .el-dropdown-link {
                            cursor: pointer;
                            font-weight: 700;
                        }
                    }

                    .user-vip-status {
                        margin-left: 14px;
                        width: 35px;
                        height: 18px;
                        line-height: 18px;
                        background: #F4F2E9;
                        border: 1px solid #C5BD9A;
                        border-radius: 9px;
                        color: #C5BD9A;
                        font-weight: 600;
                    }
                }

                .user-vip-tip {
                    margin-top: 4px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #A9B3C6;
                }

                .aph-web-user-enterprise-status {
                    margin-left: 10px;
                    width: 52px;
                    height: 18px;
                    background: #E6ECFF;
                    border: 1px solid #CDDAFF;
                    border-radius: 9px;
                    color: @system_ask_color;
                    font-size: 12px;
                }
            }
        }

        .aph-web-search {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100vw;
            position: absolute;
            left: 0;
            min-width: 1300px;

            // position: absolute;
            // flex: 1;
            // margin-left: -150px;
            // flex: 1;
            // justify-content: center;
            .content {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .aphws-box {
                flex: 1;
                width: 482px;
                // max-width: 482px;
                // min-width: 160px;
                height: 31px;
                line-height: 31px;
                background: #EAEFFF;
                border-radius: 15px;
                display: flex;
                justify-content: space-between;
                color: #95B0FF;
                cursor: pointer;
                position: relative;
                // &.min{
                //     width: 350px;
                // }
                // &.max{
                //     width: 482px;
                // }

                .aphws-b-search {
                    flex: none;
                    width: 40px;
                    text-align: left;
                    // margin-left: 12px;

                    .iconfont {
                        font-size: 12px;
                    }

                }

                .aphws-b-text {
                    text-align: center;
                    flex: auto;
                }
                .aphws-b-input {
                    display: flex;
                    width: 100%;
                    .iconfont {
                        font-size: 12px;
                        margin: 0 10px;
                    }
                    /deep/.el-input__inner {
                        padding: 0;
                        background-color: transparent;
                        border: none;
                    }

                    /deep/.el-input {
                        input::placeholder {
                            color: #95B0FF;
                        }
                    }
                }
                .history-content {
                    position: absolute;
                    top: 41px;
                    width: 100%;
                    max-height: 300px;
                    left: 0;
                    background: #FFFFFF;
                    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.28);
                    border-radius: 15px;
                    box-sizing: border-box;
                    padding: 15px 10px 10px 10px;
                    cursor: default;
                    overflow: hidden;
                    overflow-y: scroll;
                    z-index: 9999;
                    .remove-hsl {
                        width: 32px;
                        height: 32px;
                        line-height: 32px;
                        color: #366AFF;
                        background: #ECF0FB;
                        border-radius: 50%;
                        font-size: 18px;
                        position: absolute;
                        right: 10px;
                        cursor: pointer;
                    }
                    .history-list {
                        width: 100%;
                        height: auto;
                        text-align: left;
                        p {
                            color: #A9B3C6;
                            margin-bottom: 8px;
                            font-size: 14px;
                        }
                        .hl-cont {
                            width: 100%;
                            height: auto;
                            display: flex;
                            flex-wrap: wrap;
                            box-sizing: border-box;
                            padding-right: 10px;
                            .hl-view {
                                height: 18px;
                                padding: 3px 5px;
                                line-height: 18px;
                                background: #F0F2F8;
                                max-width: 100px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                border-radius: 5px;
                                font-size: 12px;
                                margin: 5px;
                                color: #404040;
                                flex: none;
                                cursor: pointer;
                            }
                        }
                        .line {
                            width: 100%;
                            height: 1px;
                            margin: 15px 0;
                            background: #F0F2F8;
                        }
                    }
                    .history-ul {
                        width: 100%;
                        .history-li {
                            width: 100%;
                            height: 28px;
                            line-height: 28px;
                            display: flex;
                            align-items: center;
                            color: #404040;
                            .history-li-left {
                                width: calc(100% - 28px);
                                text-align: left;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                cursor: pointer;
                                i {
                                    color: #D8DDEB;
                                    font-size: 14px;
                                    margin: 0 5px;
                                }
                            }
                            .history-li-right {
                                width: 28px;
                                display: none;
                                cursor: pointer;
                            }
                            .history-li-right:hover {
                                color: #366AFF;
                            }
                        }
                        .history-li:hover {
                            background: #ECF0FB;
                            .history-li-right {
                                width: 28px;
                                display: block;

                            }
                        }
                    }
                    // .history-everysearch {

                    // }
                }
            }

            .aphws-add {
                width: 70px;
                height: 28px;
                line-height: 28px;
                background: @system_ask_color;
                border-radius: 15px;
                color: white;
                display: flex;
                align-items: center;
                margin-left: 10px;
                cursor: pointer;

                .iconfont {
                    font-size: 12px;
                    font-weight: 700;
                    margin-left: 12px;
                }

                .aphws-add-text {
                    margin-left: 4px;
                }

            }
            .aigc {
                background: linear-gradient(45deg, #7a00cc, #5b4bff, #366aff);
                color: #ffffff;
                height: 28px;
                transition: background 0.3s ease, box-shadow 0.3s ease;
            }

            .aigc:hover {
                background: linear-gradient(45deg, #366aff, #5b4bff, #7a00cc);
            }

        }

        .aph-web-handle {
            z-index: 3;
            display: flex;
            align-items: center;
            margin-right: 16px;

            .win-info-tip {
                flex: none;
                padding-right: 12px;
                margin-right: 20px;
                height: 22px;
                line-height: 22px;
                // border-right: solid 1px #E1E6F8;

                .iconfont {
                    font-size: 22px;
                    color: #404040;
                    position: relative;

                    i {
                        display: block;
                        width: 8px;
                        height: 8px;
                        top: 0px;
                        right: 1px;
                        border-radius: 50%;
                        position: absolute;
                        background-color: red;
                    }
                }
            }
            .aphws-add {
                width: 70px;
                height: 28px;
                line-height: 28px;
                background: @system_ask_color;
                border-radius: 15px;
                color: white;
                display: flex;
                align-items: center;
                margin-left: 10px;
                cursor: pointer;
                padding: 0px 5px;

                .iconfont {
                    font-size: 12px;
                    font-weight: 700;
                    margin-left: 12px;
                }

                .aphws-add-text {
                    margin-left: 8px;
                }

            }
            .commonBtn {
                flex: none;
                padding: 0 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 14px;
                border-radius: 15px;
                cursor: pointer;
                margin-left: 10px;

                i {
                    padding-right: 8px;
                    font-size: 13px;
                }
            }
        }
    }

    .aigc {
        background: linear-gradient(45deg, #7a00cc, #5b4bff, #366aff);
        color: #ffffff;
        height: 28px;
        transition: background 0.3s ease, box-shadow 0.3s ease;
    }

    .aigc:hover {
        background: linear-gradient(45deg, #366aff, #5b4bff, #7a00cc);
    }

    .awh-down-btn {
        z-index: 2;
        width: 100px;
        height: 28px;
        line-height: 28px;
        background: @system_ask_color;
        border-radius: 14px;
        color: white;
        display: flex;
        align-items: center;
        margin-left: 10px;
        cursor: pointer;

        .iconfont {
            font-size: 12px;
            font-weight: 700;
            margin-left: 12px;
        }

        .aphws-add-text {
            margin-left: 4px;
        }
    }
}

.redemption-popup-content {

    .redemption-tips {
        text-align: left;
        margin-bottom: 10px;
    }

    .lighting-popup-dialog-footer {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.awh-downapp {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .awhd-title {
        font-size: 16px;
        font-weight: 700;
        margin: 12px 0 12px 0;
    }

    .awhd-des {
        color: #A9B3C6;
    }

    .awhd-img {
        height: 200px;
        width: 200px;
        margin: 8px 0;
        background-color: #E6ECFF;
    }

    .awhd-tip {
        margin-bottom: 8px;
    }
}
.aphws-add-list{
    padding: 0px 5px;
  .add-knowledge, .upload-file ,.ai-ppt{
    padding: 8px 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    i{
      padding-right: 6px;
    }
    &:hover {
      background-color: #366AFF;
      color: #ffffff;
      box-shadow: 0 4px 12px rgba(54, 106, 255, 0.4);
      border-radius: 5px;
      padding: 8px 15px;

      i {
        color: #ffffff;
      }
    }
  }
  .upload-file{
    i{
      font-size: 14px;
    }
  }
  .ai-ppt{
    i{
      font-size: 12px;
    }
  }
}
</style>
